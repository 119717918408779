import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import MyForm from "../components/form";
const joginyilatkozat = () => (
  <Layout>
    <SEO title="Jogi nyilatkozat" description="Egyénre szabott optika Zugló szívében"  />
    <div className="sitewrap xl:max-w-2xl    xl:max-w-2xl justify-center  mr-auto ml-auto      mt-10">
    <div className="  flex    mr-auto ml-auto   mt-20">
    <div className="F12F7B p-4      mb-10 mt-10 ">
 <h4>  A site (a www.vezeroptika.hu szerveren elérhető internet szolgáltatás) vagy bármely oldala megnyitásával Ön elfogadja az itt felsorolt feltételeket. Kérjük, amennyiben nem ért egyet a feltételekkel, ne nyissa meg weboldalainkat!</h4>

 <h2 className="text-center">Kérjük, figyelmesen olvassa el az alábbiakat!</h2>
 <h2>Információk</h2>
<p>A Vezér Optika minden ésszerű erőfeszítést megtett annak biztosítására, hogy az internetes weboldalán közölt minden információ pontos legyen, azok feltöltése időpontjában.</p>

<p>Ennek ellenére, a Vezér Optika nem vállal szavatosságot, garanciát, sem bármilyen egyéb jogi vagy anyagi felelősséget sem kifejezetten, sem ráutaló módon a jelen weboldalon keresztül nyújtott információkért, és fenntartja a jogot arra, hogy értesítés nélkül bármikor változtatásokat és javításokat hajtson végre. Weboldalán előforduló semmilyen pontatlanságért vagy hiányosságért, továbbá a weboldalán található információkon alapuló bármilyen döntés a felhasználó saját felelőssége. Nem vállal felelősséget a weboldalához, illetve az ott található bármilyen információhoz való hozzáférésből, vagy azok felhasználásából származó semmiféle veszteségért, elmaradt haszonért, előnyért vagy bekövetkezett kárért. Az aktuális feltételrendszert a jelen www.vezeroptika.hu internet oldalon és az Vezér Optika üzletekben kihelyezett mindenkor hatályos Szabályzat tartalmazza.</p>

<p>Az Vezér Optika nem tartozik felelősséggel azokért az esetlegesen bekövetkező károkért, veszteségekért, költségekért, amelyek a weboldalak használatából, azok használatra képtelen állapotából, nem megfelelő működéséből, üzemzavarából, az adatok bárki által történő illetéktelen megváltoztatásából keletkeznek, illetve amelyek az információtovábbítási késedelemből, számítógépes vírusból, vonal- vagy rendszerhibából, vagy más hasonló okból származnak.</p>

<h2>Szerzői jog </h2>
<p>A Vezér Optika weboldalai, az azokon található minden képi és szöveges tartalom, illetve azok elrendezése szerzői jogi, illetve védjegyoltalom alatt állnak, azok részleteinek vagy egészének bármilyen formában történő felhasználása a személyes használatot meghaladó mértékben kizárólag az Vezér Optika kifejezett erre vonatkozó írásos engedélyével lehetséges. 
</p>


<p>Cégnév: Frufa Kereskedelmi és Szolgáltató Korlátolt Felelősségű Társaság</p>
<p>Székhely: 1145 Budapest, Szugló utca 49-51. 3. emelet 1.</p>

<h2>Elérhetősége:</h2>
<p>Tel.: 06-1-212-6700</p>
<p>e-mail cím: vezeroptika@gmail.com</p>

<p>Adószám: 26194291242</p>
<p>Cégjegyzékszám: 	01 09 307075</p>
<p>Bejegyzést végző Cégbíróság: Fővárosi Bíróság</p>


</div></div>
</div>










  </Layout>
)

export default joginyilatkozat
